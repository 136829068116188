#header__ {
    min-width: 1500px;
    display:flex;
    align-items:center;
    justify-content: center;
    padding: 8px 0 8px 0;
    row-gap: 1rem;
    
    .link__ {
        display: flex;
        align-items: center;
    }
    #header__right {
      
        display:flex;
        align-items:center;
        justify-content: center;   
        
        width: 50px;
        height: 50px;
        border-radius: 5px;
        outline: none;
        border :  1px solid #ebedef
        
        
    }
    #image__header {
     
      img {
        width: 40px;
        height: 63px;
      }
    }
    #header__left {
      display: flex;
      align-items: center;
      margin-left: 30px;
        
      img {
        width: 70px;
        height: 70px;
      }
      p {
        color: #32518f;
        margin: 0;
        font-size: 24px;
        font-weight: bold;
      }
    }
  
}



#header__footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  padding: 8px 10px;
}

.border-right {
  border-right: 1px solid black;
}

#login__google {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    background-color :#ebedef;
    
    img {
        width: 22px;
        height:22px;
        margin-right: 5px;
    }
    button {
        
        height:43px;
        border: none;

        font-weight: bold;
        font-size: 14px;

    }
    #ropdown-basic {
      button {
        width: 150px!important;
      }
    }
}

.link-header {
  padding: 0 25px;
  text-decoration: none!important;
  color: blue;
  cursor: pointer;
}

.history-data:hover {
  color: red;
}

@media print {
  .hide-to-print {
    display: none;
  }
   
 }