.th-row-1 {
  width: 7%!important;
}
.th-row-2 {
  width: 3%!important;
}
.th-row-3C{
  width: 2%!important;
}
.than_sat_title {
  width: 1440px;
  margin: 0 auto;

  height: 50px;
  background: #1b61a6;

  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: #fff;
    margin-bottom: 0 !important;
  }
}

.table_tieunhi {
  width: 1440px;
  margin: 2rem auto 0 auto;

  .table td,
  .table th {
    border: 2px solid black;
    text-align: center;
    font-size: 14px;
  }

  .table th {
    // width: 6.66%;
    padding: 0;
  }
  .table td {
    width: 6%;
    font-weight: bold;
  }

  .hr-row-1 {
    width: 120%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(33deg);
    position: relative;
    left: -10px;
    top: -10px;
  }
  .hr-row-2 {
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(26deg);
    position: relative;
    left: -6px;
    top: -10px;
    width: 111%;
  }

  .hr-row-3B {
    width: 120%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(35deg);
    position: relative;
    left: -11px;
    top: -11px;
  }

  .right {
    float: right;
    padding-right: 10px;
  }
  .left {
    float: left;
    padding-left: 10px;
  }
  .th-custom {
    width: 99.08px;
  }

  .td-chi-thang {
    width: 11.25% !important;
    cursor: pointer;
  }
 
  .td-chi-ngay {
    // width: 0 !important;
  }
  .td-ng-than {
    min-width: 130px !important;
  }
  .td-chi-gio {
    width: 23.33% !important;
    cursor: pointer;
  }
  .th-chi-gio {
    width: 6.89% !important;
  }
 
  .hr-can-nam {
    width: 145%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(47deg);
    position: relative;
    left: -22px;
  }

  .td-custom {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #dee2e6 !important;
  }

  .td-custom-2 {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #dee2e6 !important;
  }
}
.click-span {
  cursor: pointer;
  font-weight: bold;
}
.background {
  background: #00bb00;
  color: #fff;
}

.background-two {
  background: #1b61a6;
  color: #fff;
}

.icon-modal {
  // margin: 0!important;

  position: absolute;
  border: 0;
  padding-left: 1rem;
  background: transparent;
  left: 25px;
}
.show {
  display: inline-block;
  // width: 20%;

  .card-body {
    height: 100vh;
    overflow-y: scroll;
  }
}



.watermark-hoagiap {
  position: absolute;
  left: 9%;
  top: 15%;
  opacity: 0.1;
  z-index: 1;
  img {
    width: 100%;
  }
}
.watermark-hoagiap-page {
  position: absolute;
  left: 29%;
  opacity: 0.1;
  z-index: 1;
  img {
    width: 100%;
  }
}
.chiday {
  font-weight: bold;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.t-50 {
  top: 50% !important;
}
.t-16{
  top: 16% !important;
}
.hr-ngay-sinh {
  width: 130%!important;
  clear: both;
  margin: 0;
  background: #000;
  transform: rotate(37deg)!important;
  position: relative;
  left: -17px;
}
// .hr-tu-tru {
//   width: 142%!important;
//   transform: rotate(-45deg)!important;
 
// }

.clr-red {
  color: red;
}

.break {
  white-space: break-spaces;
}
.display-to-print {
  display: none !important;
}

.quan-sat {
  padding: 15px 0 ;
}

.h__50 {
  height: 50px!important;
}

@media print {

  div#html-break {
    page-break-after: always;
    margin-top: 100px;
  }


  .mt__100 {
    transform: translateY(50px);
  }
  
  .display-to-print {
    display: none !important;
    
  }

  .table-responsive {
   overflow-x: hidden!important;
  } 
  #than-sat-component {
    margin-top: 300px;
    transform: translateY(5px);
  }

  #tu-tru {
    margin-top: 150px!important;
  }

  #diemvuong {
  background: #1b61a6!important;

  }
  #thuyhoa {
  background: #1b61a6!important;

  }
  #tuongquan {
  background: #1b61a6!important;

  }
  #dieuphi {
  background: #1b61a6!important;

  }
  #lactinh {
  background: #1b61a6!important;

  }
  #thamthuy {
  background: #1b61a6!important;

  }
  #tuquy {
  background: #1b61a6!important;

  }
  #ducbon {
  background: #1b61a6!important;

  }
  #kimtoa {
  background: #1b61a6!important;

  }
  #capcuoc {
  background: #1b61a6!important;

  }
  #hatinh {
  background: #1b61a6!important;

  }
  #dade {
  background: #1b61a6!important;

  }
  #loicong {
  background: #1b61a6!important;

  }
  #doankieu {
  background: #1b61a6!important;

  }
  #bachnhat {
  background: #1b61a6!important;

  }
  #doanmenh {
  background: #1b61a6!important;

  }
  #hoathuong {
  background: #1b61a6!important;

  }
  #thanghoa {
  background: #1b61a6!important;

  }
  #thiendieu {
  background: #1b61a6!important;

  }
  #thiennhat {
  background: #1b61a6!important;

  }
  #nguquy {
  background: #1b61a6!important;

  }
  #quymon {
  background: #1b61a6!important;

  }
  #thiencau {
  background: #1b61a6!important;

  }
  #kimxa {
  background: #1b61a6!important;

  }
  #bachho {
  background: #1b61a6!important;

  }
  #tutrutieunhi {
  background: #1b61a6!important;

  }
  #phisat {
  background: #1b61a6!important;

  }
  #quanmoc {
  background: #1b61a6!important;

  }
  #liemsat {
  background: #1b61a6!important;

  }
  #phisa {
  background: #1b61a6!important;

  }
  #tangsat {
  background: #1b61a6!important;

  }
 
  #chihour {
  background: #1b61a6!important;

  }
}

