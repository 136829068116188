.__react_component_tooltip {
  z-index: 999999!important;
}


#container {
  
  width: 100%;
  background: white;
  position: relative;
  z-index: 20;
 
}

  .row {
    margin-left: 0!important;
  }

  .table {
     td {
      vertical-align: middle!important;
    }
  }

 

  .flex__ {
    display: flex;
    justify-content: center;
  }
.div__footer {
  background: #14171d;
  width: 1440px;
  margin: 0 auto;

  min-height: 374px;

  .div__tren {
    display: flex;
    justify-content:space-evenly;
    align-items: center;
    padding: 15px 50px 0;

    img {
      width: 340px;
      height: 90px;
      padding-right: 10px;
    }
    .info__footer {
      text-align: center;
      
      p {
        margin: 0;
        font-size: 14px;
        color: white;
      }
    }

 
  }
  .div__duoi {
    display: flex;
    justify-content: space-around;
    padding: 25px 50px 15px 50px;

    a {
      display: inline-block;
      text-decoration: none;
      color: white;
      transition: all 0.4s;
      &:hover {
        color: red;
      }
    }
    li {
      list-style: none;
    }
  }
  .title__footer {
    display: flex;
    justify-content: center;
    color: white;
    font-size: 14px;
  }
}

.social__ {
  display: flex;

  .twitter {
    padding: 0 8px 0 8px;
  }
  .google {
    padding: 0 8px 0 0;
  }

  a {
    display: inline-block;
    border: 1px solid #fff;
    border-radius: 50%;
    text-align: center;

    width: 40px;
    height: 40px;
    line-height: 30px;
    transition: all 0.4s;

    &:hover {
      border: 1px solid red;
    }
  }
  svg {
    width: 20px;
    height: 20px;
    color: white;
    transition: all 0.4s;
  }
}
.loading {
  display: none;
  position: absolute;
  top: 0;
  min-height: 1500px;
  opacity: 0.5;
  background: #000000D9;
  width: 100%;
  height: 100%;
  z-index: 99999;
}

.imagee {
  display: none;

    position: fixed;
    top: 30%;
    left: 39%;
    margin:-60px 0 0 -60px;
    z-index: 99999;
    -webkit-animation:spin 4s linear infinite;
    -moz-animation:spin 4s linear infinite;
    animation:spin 4s linear infinite;
}

.flexx__ {
  width: 100%;
  display: flex;
  justify-content: center;
  
}

.flexxx__ {
  width: 100%;
  display: flex;
  
  
}

.text__wrap {
  width:  150px;
  height: 131px;
  text-overflow: break-word;
}

.show {
  display: block;
}

.table-bordered {
  border : 0!important;
}

.notoption {
  max-width: 200px!important;
}

@media print {
  .hide-to-print {
    display: none;
  }
   
 }


 @media (max-width: 1430px) {
  #app__ {
    min-width: 1440px;
  }
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }