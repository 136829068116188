sup {
  top: -1em!important;
}
.title__print {
  text-align: center;
  font-weight: bold;
  margin-top: 25px;
  font-size: 16px;
}

.sub__title {
 text-align: center;
 font-weight: bold;
 margin-bottom: 15px;
 font-size: 16px;

}

.hr__print {
  width: 80%;
  margin: 0 10%;
  padding-top: 20px;
 border-top: 1px solid black;
}

.title__battu {
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  position: relative;
  z-index: 1000;
 }
.input-group {
  display: flex !important;
  align-items: center !important;
  width: 30% !important;
  padding: 0 15px 0 15px;
  position: relative;
  z-index: 1000;
  label {
    margin: 0;
    padding-right: 20px;
  }
}
.button__result {
  display: flex;
  justify-content: center;
  padding: 25px 0;
  position: relative;
  z-index: 1000;
}

.table {
  th {
    vertical-align: middle!important;
  }
}

.watermark {
  position: absolute;
  top: 11%;
  left: 50%;
  transform: translateX(-50%);
  text-align: center;
  z-index: 10;
  img {
    width: 800px;
    opacity: 0.1;
  }
}


.td__2 {
  width: 80px!important;
}


.input__group {
  display: flex;
  align-items: center !important;
  justify-content: center;
  flex-wrap: wrap;
  padding: 50px 25px 25px 25px;
}

.b9 {
  background: white !important;
}
.main__content {
  display: flex;
  justify-content: center;
  border-bottom: dashed 1px #1B61A6;
  position: relative;
  
  z-index: 1000;
  background-color: #ffff;
}

.main__left {
  display: flex;
  align-items: center;

 

  padding: 65px 0 0 25px;

  .left__ {
    width: 250px;
    padding: 10px 10px 10px 10px;
    border: 1px solid black;
    font-weight: bold;
  }
}

.left__footer {
 
  
  margin: 55px 0 0 24px;
  border: 1px solid black;

  .footer__ {
    font-weight: bold;
  
    padding: 10px 10px 10px 10px;
  }
}

.box__right {
  margin-left: 15px;
  margin-right: 15px;
  table {
    width: 650px;
    height: 700px;
  }
}

.talbe-hoagiap {
  width: 250px!important;
}
.info-hoagiap {
  display: inherit;
  text-align: center;
  margin-bottom: 10px;
  cursor: pointer;
  color: blue;
}

.hide {
  visibility: hidden;
}

.search-letter {
  display: inline-block;
  width: 50px;
  height: 55px;
  color: #fff;
  background-color: #1B61A6;
  font-size: 1.5rem;
  padding: 9px 15px;
  border-top-left-radius: 21px;
  border-top-right-radius: 21px;
  cursor: pointer;
}

.show-letter {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  
}

.child {
  position: relative;
  background-color: #ff7400!important;
  padding: 8px;
}

.title__print {
  display: none;
}

.sub__title {
  display: none;
}

.hr__print {
  display: none;
}

.title__battu {
  display: none;
}

.note__ {
  display: none;
}
.info__user {
  display: none;
}

.vertical__ {
  vertical-align: center!important;
}

.fz__25 {
  font-size: 22px;
  font-weight: bold;
}
.fz__15 {
  font-size: 12px;
  color : #010000;
  margin-right: 5px;
  font-weight: normal;

  margin-bottom: 5px;
}

.fz__16 {
  font-size: 14px;
}

.fz__18 {
  font-size: 16px;
  font-weight: normal;

}

.fz__12 {
  font-size: 12px;
  font-weight: normal;

}

.fz__10 {
  font-size: 10px;
  font-weight: normal;
}

.transs {
}

.fz__22 {
  font-size: 20px;
  font-weight: normal;
  color: white;
}

.clr__ {
  color: #1B61A6;
}

.bg__ {
  background-color: #1B61A6;
}

.bg__orgrane  {
  background-color: #ff7400;
}
.bg__2 {
  background-color: #ff7400;

}
#bg__b {
 
  border: 3px solid black!important;
 }
.fz__18 {
  font-size:18px;
}

.fw__b {
  font-weight: bold;
}

.clr__white {
  color: white;
}
.clr__black {
  color: black;
  font-weight: normal;
}

.border__ {
  border : 2px solid #707070!important;
}
.border__1px {
  border : 1px solid #707070!important;

}
.switch__ {
  
  margin-left: 20px;
}

  #info__printtt {
    margin-top: 25px;
    border-top: dashed 1px #1B61A6;
    border-bottom: dashed 1px #1B61A6;
   padding: 15px 0;
   position: relative;
   z-index: 2000;
   font-size: 20px;
   display: none;
}

#info__print {
    display: flex;
    justify-content:center;
}
#info__printt {
  display: flex;
  justify-content:center;
}

.item-search {
  margin: 0 15px;
  cursor: pointer;
  transition: all 0.3s; 
}

.item-search:hover {
  color: blue;
}

.fw__bold {
  font-weight: bold;
  padding: 0 10px;
}

.fz__20 {
  font-size: 20px;
}
.background_ {
  background-color: #B2CFEA;

}
#bg_cot1 {
  background-color: #B2CFEA;
}
#bg_cot2 {
  background-color: #B2CFEA;
}
#bg_cot3 {
  background-color: #B2CFEA;
}
#bg_cot4 {
  background-color: #B2CFEA;
}
#bg_cot5 {
  background-color: #B2CFEA;
}
#bg_cot6 {
  background-color: #B2CFEA;
}
#bg_cot7 {
  background-color: #B2CFEA;
}
#bg_cot8 {
  background-color: #B2CFEA;
}
#bg_cot9 {
  background-color: #B2CFEA;
}
#bg_cot10 {
  background-color: #B2CFEA;
}

@media print {

    

  #page-break {
    transform: translateY(-20px);
    background-color:transparent!important;
  }

  .page-break-table {
    margin-top: 60px;
  }
  .page-break-table-2 {
    margin-top: 60px;
  }

  #than-sat-component {
    display: none;
  }
  #hide-title-celeb {
    display: none!important;
  }

  #table_huong {
    transform: translateY(-150px);
  }
  .table__dv {
    transform: translateY(-1100px);
  }
  .table__ln {
    transform: translateY(-1100px);

  }

  #none {
      border : none!important;
  }
  #none_bg {
    border : none!important;
    background-color: #B2CFEA!important;
}
  #none2 {
    border : none!important;
  }
  #none2_bg {
    border : none!important;
    background-color: #B2CFEA!important;

  }
  #none3 {
    border-top : none!important;
    border-bottom : none!important;
    border-left : 1px solid gray!important;
    border-right : 1px solid gray!important;
}
#none3_bg {
  border-top : none!important;
  border-bottom : none!important;
  border-left : 1px solid gray!important;
  border-right : 1px solid gray!important;
  background-color: #B2CFEA!important;

}

  #none4 {
    border-top : none!important;
    border-bottom : none!important;
    border-left : none;
    border-right : 1px solid gray!important;
}
#none4_bg {
  border-top : none!important;
  border-bottom : none!important;
  border-left : none;
  border-right : 1px solid gray!important;
  background-color: #B2CFEA!important;

}

#table_dv {
  top: -10px;
}
 
#bg__dc12 {
  background-color: #B2CFEA!important;
}

  #bg__lnn{
    background-color: #1B61A6!important;

  }
  #bg__age{
    background-color: #1B61A6!important;

  }
  #bg__lnntc{
    background-color: #1B61A6!important;

  }
  #bg__lnndc{
    background-color: #1B61A6!important;

  }
  #bg__lnntcc{
    background-color: #1B61A6!important;

  }
  #bg__lnnna{
    background-color: #1B61A6!important;

  }
  #bg__lnnts{
    background-color: #1B61A6!important;

  }
  #bg__lnntss{
    background-color: #1B61A6!important;

  }

  #bg__dv10{
    background-color: #ff7400!important;

  }

  #bg__dv10a{
    background-color: #ff7400!important;

  }
 
 #bg__{
  background-color: #1B61A6!important;

 }
 #bg__ht {
  background-color: #1B61A6!important;

 }
 #bg__hx {
  background-color: #1B61A6!important;

 }
 #bg__h{
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__d {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__m {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__y {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__dv {
  background-color: #ff7400!important;
  color: white!important;
  height: 64px!important;
 }
 #bg__ln {
  background-color: #ff7400!important;
  color: white!important;
  height : 64px!important;
 }
 #bg__tc {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__dc {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__tcc {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__na {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__ts {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__tss {
  background-color: #1B61A6!important;
  color: white!important;
 }
 #bg__dvht {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__tcdv{
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__nadv {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__tsdv {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__dvts {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__lnht {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__lntc {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__lnna {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__lnts {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__lntss {
  background-color: #ff7400!important;
  color: white!important;
 }
 #bg__a {
  background-color: #1B61A6!important;
 }
 #bg__b {
  background-color: #ff7400!important;
  border: 3px solid black!important;
 }
 #bg__c {
  background-color: #ff7400!important;
 }

 #bg__tcd {
   background-color: #B2CFEA!important;
 }
 #bg__tcd2 {
  background-color: #B2CFEA!important;
}
#bg__tcd3 {
  background-color: #B2CFEA!important;
}
 #bg__dcd {
  background-color: #B2CFEA!important;
}
#bg__nad {
  background-color: #B2CFEA!important;
}
#bg__tsd{
  background-color: #B2CFEA!important;
}
#bg__tssd {
  background-color: #B2CFEA!important;
}
#bg__nhd {
  background-color: #B2CFEA!important;
}

#bg__orgrane  {
  background-color: #ff7400!important;
}
#bg__orgranetc  {
  background-color: #ff7400!important;
}
#bg__orgranemc  {
  background-color: #ff7400!important;
}
#bg__orgrane_hx  {
  background-color: #ff7400!important;
}
  .margin__print {
    margin-top: 80px;
    z-index: 9999!important;
  }
  .margin__print2 {
    margin-top: 60px;
  }

  .margin__print_left {
    margin-left: 25px;
  }
 
  .main__content {
    top : -50px;
    border-bottom: none;
    z-index: 1000;
  }
  .box__right {
    font-size: 12px;
  }

  .fz__16 {
    font-size: 13px;
  }

  .fz__18 {
    font-size: 16px;
  }
 #box__ {
   height: 600px !important;
 }

 #bg_cot1 {
   background-color: #B2CFEA!important;
 }
 #bg_cot2 {
   background-color: #B2CFEA!important;
 }
 #bg_cot3 {
   background-color: #B2CFEA!important;
 }
 #bg_cot4 {
   background-color: #B2CFEA!important;
 }
 #bg_cot5 {
   background-color: #B2CFEA!important;
 }
 #bg_cot6 {
   background-color: #B2CFEA!important;
 }
 #bg_cot7 {
   background-color: #B2CFEA!important;
 }
 #bg_cot8 {
   background-color: #B2CFEA!important;
 }
 #bg_cot9 {
   background-color: #B2CFEA!important;
 }
 #bg_cot10 {
   background-color: #B2CFEA!important;
 }
 
 #bg_hang1 {
  background-color: #B2CFEA!important;
}
#bg_hang2 {
  background-color: #B2CFEA!important;
}
#bg_hang3 {
  background-color: #B2CFEA!important;
}
#bg_hang4 {
  background-color: #B2CFEA!important;
}
#bg_hang5 {
  background-color: #B2CFEA!important;
}
#bg_hang6 {
  background-color: #B2CFEA!important;
}
#bg_hang7 {
  background-color: #B2CFEA!important;
}
#bg_hang8 {
  background-color: #B2CFEA!important;
}

  
  
  .hide-to-print {
    display: none;
   
  }

  .hide__border__td {
    visibility: hidden;
  
 }

 .clr__print {
  color: black!important;
}
 .watermark {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 2000;
 }
 
 
.button__prev {
  border: none;
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.button__next {
  border: none;
  width: 20px;
  height: 20px;
  margin-left: 10px;
}

 .title__print {
   display: block;
   text-align: center;
   font-weight: bold;
   margin-top: 25px;
   font-size: 16px;
 }

 .sub__title {
   display: block;
  text-align: center;
  font-weight: bold;
  margin-bottom: 15px;
  font-size: 16px;

 }

 .hr__print {
   display: block;
   width: 80%;
   margin: 0 10%;
   padding-top: 20px;
  border-top: 1px solid black;
 }

 .title__battu {
  display: block;
  text-align: center;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 28px;
  position: relative;
  z-index: 1000;
 }

 .note__ {
   display: inline-block;
   width: 50%;
   height: 100%;
   text-align: left;
   font-weight: bold;
   margin-left: 25px;
   font-size: 12px;

 }
 .info__user {
  display: block;
  font-weight: bold;
  margin-left:2%;
  margin-top: 15px;
  font-size: 16px;

 }
 .page__2_print {
   padding-top: 10px;
 }
 .tranform__ {
   transform: translateY(0);
 }
  
#info__printtt {
  display: block;
  
}
.block__print {
  display: block!important;
}
#dvht {
  height: 635px!important;
  margin-left: 65px;
}
#lnht {
  height: 635px!important;

}
#lnhtts {
  height:  101px!important;
}
#dvhtts {
  height:  101px!important;

}
#tsbox {
  height:  101px!important;

}
#boxtss {
  height:  82px!important;

}
#nabox {
  height:  82px!important;

}
#tcbox {
  height: 88px!important;
}
.text__wrap {
  
  height: 0!important;
}
.head_box_celerity {
  height: 36px!important;
}


  .box__right {
    table {
      width: 709px;
      height: 635px;
    }
  }
 }

