* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  margin: 0;
  

  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -webkit-print-color-adjust: exact !important;

}

.modal-dialog,
.modal-content {
    /* 80% of window height */
    height: 100%;
}

.modal-body {
    /* 100% = dialog height, 120px = header + footer */
    max-height: calc(100% - 160px);
    overflow-y: scroll;
}

@page {
  size: A3 landscape !important;
  margin: 0;
}

@media print {
  html {
    -webkit-print-color-adjust: exact !important; 

  }
  body {
    -webkit-print-color-adjust: exact !important; 

  }

 .hide-to-print {
   display: none;
 }

 div#page-title-note {
  transform: translateY(20px);

  width: 1440px;
  display: block !important;
  margin: 0 auto;
  font-weight: bold;
  text-align: center;
  font-size: 28px;
 }
 div#page-note {
  width: 1440px;
  transform: translateY(20px);

  display: block !important;
  margin: 0 auto;

}
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
