.than_sat_title {
  width: 1440px;
  margin: 0 auto;

  height: 50px;
  background: #1b61a6;

  display: flex;
  justify-content: center;
  align-items: center;

  h3 {
    color: #fff;
    margin-bottom: 0 !important;
  }
}
#cucdacbiet {
  margin-top: 50px

}
.table_thansat {
  width: 1440px;
  margin: 2rem auto 0 auto;

  .table td,
  .table th {
    border: 2px solid black;
    text-align: center;
    font-size: 14px;
  }

  .table_thansat th {
    width: 6.66%;
    padding: 0;
  }
  .table td {
    width: 6%;
    font-weight: bold;
  }

  hr {
    width: 135%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(42deg);
    position: relative;
    left: -17px;
  }
  .hr-chi-thang {
    width: 128%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(37deg);
    position: relative;
    left: -17px;
  }

  .right {
    float: right;
    padding-right: 10px;
  }
  .left {
    float: left;
    padding-left: 10px;
  }
  .th-custom {
    width: 99.08px;
  }

  .td-chi-thang {
    width: 11.25% !important;
    cursor: pointer;
  }
  .th-chi-ngay {
    min-width: 99.08px;
  }
  .th-nien-chi {
    min-width: 99.08px;
  }
  .td-chi-ngay {
    width: 0 !important;
  }
  .td-ng-than {
    min-width: 130px !important;
  }
  .td-chi-gio {
    width: 23.33% !important;
    cursor: pointer;
  }
  .th-chi-gio {
    width: 6.89% !important;
  }
  .td-can-nam {
    cursor: pointer;
  }
  .td-nien-chi {
    cursor: pointer;
  }
  .td-ngay-sinh {
    cursor: pointer;
  }
  .hr-can-nam {
    width: 145%;
    clear: both;
    margin: 0;
    background: #000;
    transform: rotate(47deg);
    position: relative;
    left: -22px;
  }

  .td-custom {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #dee2e6 !important;
  }

  .td-custom-2 {
    border-top: none !important;
    border-left: none !important;
    border-right: none !important;
    border-bottom: 2px solid #dee2e6 !important;
  }
}

.click-span {
  cursor: pointer;
  font-weight: bold;
}
.background {
  background: #00bb00!important;
  color: #fff;
}

.background-two {
  background: #1b61a6;
  color: #fff;
}

.icon-modal {
  // margin: 0!important;

  position: absolute;
  border: 0;
  padding-left: 1rem;
  background: transparent;
  left: 25px;
}
.show {
  display: inline-block;
  // width: 20%;

  .card-body {
    height: 100vh;
    overflow-y: scroll;
  }
}
.modal {
  width: 100%;
}
.modal-backdrop {
  width: 100%;
}
.content-modal {
  width: 100%;
  text-align: center;
  position: relative;
  z-index: 999;
}

.content-modal-show {
  width: 80%;
 
  text-align: center;
  position: relative;
  z-index: 999;
}

.watermark-hoagiap {
  position: absolute;
  left: 9%;
  top: 15%;
  opacity: 0.1;
  z-index: 1;
  img {
    width: 100%;
  }
}
.watermark-hoagiap-page {
  position: absolute;
  left: 29%;
  opacity: 0.1;
  z-index: 1;
  img {
    width: 100%;
  }
}
.chiday {
  font-weight: bold;
  margin-bottom: 0.5rem;
  cursor: pointer;
}
.t-50 {
  top: 50% !important;
}
.t-16{
  top: 16% !important;
}
.hr-ngay-sinh {
  width: 130%!important;
  clear: both;
  margin: 0;
  background: #000;
  transform: rotate(37deg)!important;
  position: relative;
  left: -17px;
}
.hr-tu-tru-than-sat {
  width: 142%!important;
  transform: rotate(-40deg)!important;
 
}

.clr-red {
  color: red;
}

.break {
  white-space: break-spaces;
}
.display-to-print {
  display: none !important;
}

@media print {
  .background {
    background: #00bb00!important;
    color: #fff;
  }
  #dungThan {
    background: #00bb00!important;
    color: #fff;
    border: none!important;
  }
  div#cucdacbiet {
    margin-top: 1000px;
  }

  div#tu-tru{
    page-break-before: always;

  }

  .mt__100 {
    transform: translateY(50px);
  }
  
  .display-to-print {
    display: none !important;
    
  }

  .table-responsive {
   overflow-x: hidden!important;
  } 
  #than-sat-component {
    margin-top: 300px;
    transform: translateY(5px);
  }
  
  #tu-tru {
    margin-top: 150px!important;
  }

  #canday {
    background: #00bb00!important;
  }
  #taqn {
    background: #00bb00!important;
  }
  #hamtri {
    background: #00bb00!important;
  }
  #vanxuong {
    background: #00bb00!important;
  }
  #locthan {
    background: #00bb00!important;
  }
  #duongnhan {
    background: #00bb00!important;
  }
  #hongdiem {
    background: #00bb00!important;
  }
  #hocduong {
    background: #00bb00!important;
  }
  #luuha {
    background: #00bb00!important;
  }
  #phidao {
    background: #00bb00!important;
  }
  #thientai {
    background: #00bb00!important;
  }
  #kimdu {
    background: #00bb00!important;
  }
  #quythuc {
    background: #00bb00!important;
  }
  #quyhop {
    background: #00bb00!important;
  }
  #luchopquy {
    background: #00bb00!important;
  }
  #tcqn {
    background: #00bb00!important;
  }
  #qaqn {
    background: #00bb00!important;
  }
  #mokho {
    background: #00bb00!important;
  }
  #tdqn {
    background: #00bb00!important;
  }
  #ndqn {
    background: #00bb00!important;
  }
  #nguyetkhong {
    background: #00bb00!important;
  }
  #nguphu {
    background: #00bb00!important;
  }
  #thienthu {
    background: #00bb00!important;
  }
  #dichma2 {
    background: #00bb00!important;
  }
  #thienxa {
    background: #00bb00!important;
  }
  #chimonth {
    background: #00bb00!important;
  }
  #longduc- {
    background: #00bb00!important;
  }
  #kimquy- {
    background: #00bb00!important;
  }
  #hongloan- {
    background: #00bb00!important;
  }
  #ngaysinh-2 {
    background: #00bb00!important;
  }
  #tutru {
    background: #00bb00!important;
  }
  #nam {
    background: #00bb00!important;
  }
  #nu {
    background: #00bb00!important;
  }
  #hoacai {
  background: #1b61a6!important;

  }
  #tuongtinh {
  background: #1b61a6!important;

  }
  #dichma {
  background: #1b61a6!important;

  }
  #daohoa {
  background: #1b61a6!important;

  }
  #huyetnhan {
  background: #1b61a6!important;

  }
  #cothan {
  background: #1b61a6!important;

  }
  #quatu {
  background: #1b61a6!important;

  }
  #tangmon {
  background: #1b61a6!important;

  }
  #kiepsat {
  background: #1b61a6!important;

  }
  #cachgiac {
  background: #1b61a6!important;

  }
  #vongthan {
  background: #1b61a6!important;

  }
  #nguquy {
  background: #1b61a6!important;

  }
  #nguyetduchop {
  background: #1b61a6!important;

  }
  #nguyetpha {
  background: #1b61a6!important;

  }
  #nhatpha {
  background: #1b61a6!important;

  }
  #thienduchop {
  background: #1b61a6!important;

  }
  #ngocmon {
  background: #1b61a6!important;

  }
  #chiday {
  background: #1b61a6!important;

  }
  #canyear {
  background: #1b61a6!important;

  }
  #tcqn2 {
  background: #1b61a6!important;

  }
  #qaqn2 {
  background: #1b61a6!important;

  }
  #ducthan {
  background: #1b61a6!important;

  }
  #canloc {
  background: #1b61a6!important;

  }
  #duongnhan2 {
  background: #1b61a6!important;

  }
  #hocduong2 {
  background: #1b61a6!important;

  }
  #hocsi {
  background: #1b61a6!important;

  }
  #hongdiem2 {
  background: #1b61a6!important;

  }
  #kimdu2 {
  background: #1b61a6!important;

  }
  #locthan2 {
  background: #1b61a6!important;

  }
  #luuha2 {
  background: #1b61a6!important;

  }
  #luuha- {
  background: #1b61a6!important;

  }
  #mocduc {
  background: #1b61a6!important;

  }
  #ngocduong {
  background: #1b61a6!important;

  }
  #phinhan {
  background: #1b61a6!important;

  }
  #phinhan- {
  background: #1b61a6!important;

  }
  #phuctinh {
  background: #1b61a6!important;

  }
  #phinhan- {
  background: #1b61a6!important;

  }
  #thientai2 {
  background: #1b61a6!important;

  }
  #vanxuong2 {
  background: #1b61a6!important;

  }
  #ndh {
  background: #1b61a6!important;

  }
  #tdh {
  background: #1b61a6!important;

  }
  #nguyetpha {
  background: #1b61a6!important;

  }
  #ngocmon {
  background: #1b61a6!important;

  }
  #chihour {
  background: #1b61a6!important;

  }
  #luctu {
  background: #1b61a6!important;

  }
  #damuu {
  background: #1b61a6!important;

  }
  #thaplinh {
  background: #1b61a6!important;

  }
  #hongdiem2 {
  background: #1b61a6!important;

  }
  #amsaiduongthac {
  background: #1b61a6!important;

  }
  #khoicuong {
  background: #1b61a6!important;

  }
  #coloan {
  background: #1b61a6!important;

  }
  #nhatduc {
  background: #1b61a6!important;

  }
  #nhatquy {
  background: #1b61a6!important;

  }
  #tamdaiquy {
  background: #1b61a6!important;

  }
  #thapacdaibai {
  background: #1b61a6!important;

  }
  #thoaithan {
  background: #1b61a6!important;

  }
  #tiepthan {
  background: #1b61a6!important;

  }
  #ngaysinh {
  background: #1b61a6!important;

  }
}

.active {
  color: red
  
}